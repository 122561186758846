@font-face {
  font-family: "ATT Aleck Cd";
  font-weight: bold;
  src: url(fonts/ATTAleckCd_Blk.ttf);
}

@font-face {
  font-family: "ATT Aleck Cd";
  font-weight: 300;
  src: url(fonts/ATTAleckCd_Md.ttf);
}

@font-face {
  font-family: "ATT Aleck Sans";
  font-weight: normal;
  src: url(fonts/ATTAleckSans_Rg.ttf);
}

@font-face {
  font-family: "ATT Aleck Sans";
  font-weight: bold;
  src: url(fonts/ATTAleckSans_Bd.ttf);
}

@font-face {
  font-family: "ATT Aleck Sans";
  font-weight: bold;
  font-style: italic;
  src: url(fonts/ATTAleckSans_BdIt.ttf);
}


html,
body {
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

div {
  font-family: "ATT Aleck Sans";
  font-style: italic;
  text-transform: uppercase;
}

.button_container {
  position: relative;
  cursor: pointer;
}

.polygon {
  width: 100%;
  height: 100%;
  clip-path: polygon(10% 100%, 100% 100%, 90% 0%, 0% 0%);
  background-color: #fff;
  box-shadow: 14px 14px 0 3px #00abe1;
  position: relative;
  text-align: center;
  line-height: 100%;
  z-index: 2;
}

.polygon_shadow {
  width: 100%;
  height: 100%;
  clip-path: polygon(10% 100%, 100% 100%, 90% 0%, 0% 0%);
  background-color: #00abe1;
  position: absolute;
  top: 7px;
  left: 15px;
  z-index: 1;
}