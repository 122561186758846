@font-face {
  font-family: "Gilroy";
  src: url('./resources/maxa/fonts/GILROY-LIGHT.OTF');
}

@font-face {
  font-family: "Gilroy";
  font-weight: bold;
  src: url('./resources/maxa/fonts/GILROY-EXTRABOLD.OTF');
}


/* @font-face {
  font-family: "ATTAleck";
  src: url('./resources/maxa/fonts/GILROY-LIGHT.OTF');
}

@font-face {
  font-family: "ATTAleck";
  font-weight: bold;
  src: url('./resources/att/fonts/ATT-Aleck-Sans-Black-Regular.OTF');
} */

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

img[alt="close-typeform"]{
  display: none !important;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.slick-dots {
  display: block !important;
  position: fixed !important;
  bottom: 1% !important;
}

.slick-next {
  right: 10%;
}

.slick-prev {
  left: 10%;
  z-index: 1;
}

.slick-dots li.slick-active button:before {
  color: #275DAF;
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 15px;
  color: #C4C4C4;
  opacity: 1;
}

.maxa {
  font-family: 'Gilroy', sans-serif;
}

.maxa .slick-dots li.slick-active button:before {
  color: #a7329a;
  opacity: 1;
}

.maxa .App-link {
  color: #fff !important;
  text-decoration: none !important;
  padding: 5px 70px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 2px solid #fff !important;
  cursor: pointer !important;
}


div[data-qa=close-button-mobile] {
  display: none !important;
}

.ATTSamsung {
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  height: 100vh;
  width: 100vw;
  background-color: #2FA9DD;
}

.ATTSamsung p {
  text-transform: none !important;
  white-space: normal !important;
  font-family: "ATT Aleck Sans";
  font-style: normal;
  font-size: 3vh;
  padding-right: 8%;
  padding-left: 8%;
  color: #fff;
  margin-top: 15vh !important;
}

.ATTSamsung .introduction p {
  margin-top: 10% !important;
}

.Title-ATT {
  font-weight: bold;
  font-size: 4vh !important;

}

.ATT-Section {
  height: 100vh;
  width: 100vw;
  margin: 0;
  display: inline-block;
  text-align: center;
  position: relative;
  padding-top: 4vh;
}

.ATTSamsung-button {
  background: white;
  border: 0;
  padding: 10px;
  border-radius: 40px;
  width: 80%;
  font-family: "Gilroy";
  font-weight: bold;
  color: #2FA9DD;
  position: absolute;
  bottom: 14%;
  left: 10% !important;
  right: 10% !important;
  font-size: large;

}

.ATTSamsung-button:focus {
  outline: 0px solid transparent;
}

.input-form-control {
  background: none;
  color: #fff;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  text-align: center;
  width: 75%;
  font-size: 3vh;
  margin-top: 18%;

}

.ATTSamsung .input-form-control:focus {
  border-bottom: 2px solid;
  outline: 0px solid transparent;

}

.ATTSamsung ::-webkit-input-placeholder {
  /* Edge */
  color: white;
}

.ATTSamsung :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.ATTSamsung ::placeholder {
  color: white;
}

.camera-buttons {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.camera-button {
  margin-top: 10px;
}