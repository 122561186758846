
html,body{
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
}
@font-face{
    font-family: Pangram;
    src: url(fonts/Pangram-Medium.otf);
}

@font-face{
    font-family: Pangram;
    src: url(fonts/Pangram-Medium.otf);
    font-weight: bold;
}
@font-face{
    font-family: Pangram;
    src: url(fonts/Pangram-Light.otf);
    font-weight: 300;
}

div{
    font-family: Pangram;
}

/* iframe{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right: 0;
    margin: auto;
    width: 100%;
} */