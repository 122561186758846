
html,body{
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
}
@font-face{
    font-family: Pangram;
    src: url(fonts/Pangram-Medium.otf);
}

@font-face{
    font-family: Pangram;
    src: url(fonts/Pangram-Medium.otf);
    font-weight: bold;
}
@font-face{
    font-family: Pangram;
    src: url(fonts/Pangram-Light.otf);
    font-weight: 300;
}

div{
    font-family: Pangram;
}
.slick-dots li.slick-active button:before {
    color: #00E4A0;
    opacity: 1;
}

.motorola-btn{
    cursor: pointer;
    display: block;
    /* width:60%; */
    padding: 15px 0;
    margin-top: 50px;
    background-color: #00E4A0;
}

.react-html5-camera-photo{
    height: 100vh;
}
.react-html5-camera-photo video{
    height: 100%;
}
#container-circles {
    position: absolute;
    left: 50%;
    bottom: 10%!important;
}