.container {
  background: #ffefba; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #ffffff, #ffefba); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #e3dabd,
    #ffffff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.botonGuardar {
  background: transparent; /* fallback for old browsers */
  border-style: solid;
  border-width: 1px;
  border-color: #000000;
}
a {
  color: #000000;
  text-decoration: none;
}
